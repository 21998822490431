
/**
 * Календарь
 */

jQuery(function() {

    $('.js__calender-def').datepicker({
        //defaultViewDate: { year: 2019, month: 04, day: 9 },
        language: "ru",
        todayHighlight: true,
        templates: {
            leftArrow: '<span class="sr-only">Предыдущее</span>',
            rightArrow: '<span class="sr-only">Следующее</span>'
        }
    });

});



/**
 * Главные слайды
 */

jQuery(function() {

    $('.js__slides-main').owlCarousel({
        autoHeight: true,
        loop: true,
        items: 1,
        dots: true,
        nav: false,
    });

});



/**
 * О компании
 */

jQuery(function() {

    $('.js__slides-about').owlCarousel({
        autoHeight: true,
        loop: true,
        items: 1,
        dots: true,
        nav: false,
    });

});